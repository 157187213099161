@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&amp;display=swap');
// @import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

@import './icons.scss';

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// Light Theme
@import './bootstrap.scss';
@import './app.scss';

// RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

// body {
//     * {
//         outline: none;
//         font-family: 'Montserrat', sans-serif;
//     }
// }

@font-face {
    font-family: 'MYRIADPRO';
    src: url(../../assets/fonts/myriad-pro/MYRIADPRO-REGULAR.OTF);
}

@font-face {
    font-family: 'MYRIADPRO';
    src: url(../../assets/fonts/myriad-pro//MYRIADPRO-BOLD.OTF);
    font-weight: bold;
}

body {
    color: #112233 !important;
    font-family: 'MYRIADPRO' !important;
    font-size: 20px !important;
}
* {
    outline: none;
    // font-family: 'Montserrat', sans-serif;
    font-family: 'MYRIADPRO';
}

.react-bootstrap-table-page-btns-ul {
    margin-bottom: 0px;
}
.fz-16px {
    font-size: 16px !important;
    color: #112233 !important;
}
.close {
    background: transparent;
    border: 0;
    font-size: 10px;
    padding: 1.35rem 1.25rem;
    background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.5;
    width: 1em;
    height: 1em;
    z-index: 2;
    span {
        display: none;
    }
}

.rangeslider__fill {
    background-color: #556ee6 !important;
}
.rdw-editor-main {
    border: 1px solid $gray-300;
    height: 239px;
}

.dz-message {
    text-align: center;
    margin: 2em 0;
}
//Crypto enteries
.bs-select select {
    width: auto !important;
}
.plan-list .tab-str tr th{background: #8a8b8c   !important;}
.apexcharts-canvas {
    text {
        fill: #adb5bd !important;
    }
}

.san-check th{font-size: 20px;  font-family: 'MYRIADPRO' !important;}
//
// Columns
//

.card-columns {
    .card {
        margin-bottom: $grid-gutter-width;
    }

    @include media-breakpoint-up(sm) {
        column-count: 3;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;

        .card {
            display: inline-block; // Don't let them vertically span multiple columns
            width: 100%; // Don't let their width change
        }
    }
}

//carousel
.carousel-custom {
    .carousel-dark {
        .carousel.slide {
            .carousel-indicators [data-bs-target] {
                background: #000;
                border-color: #fff;
            }
        }
    }
    .carousel.slide {
        .carousel-indicators [data-bs-target] {
            border: 1px solid #000;
            background: #fff;
            height: 2px;
            opacity: 0.4;
        }
        .carousel-indicators .active {
            opacity: 1;
        }
    }
}

//cursor-pointer
.cursor-pointer {
    cursor: pointer;
}

//form editor
.wysiwyg-custom {
    .rdw-link-modal {
        height: auto;
    }
    .rdw-embedded-modal-header {
        .rdw-embedded-modal-header-option {
            width: auto;
        }
    }
}

//form xeditable
.formXeditable-custom {
    button {
        margin-left: 5px;
    }
}

//User List
.dropdown-toggle {
    &:after {
        display: none !important;
    }
}

//react-table
.react_table {
    thead {
        tr {
            th {
                div:last-child {
                    min-height: 38px;
                }
            }
        }
    }
}
// CSS
.w-15 {
    width: 15%;
}
.w-20 {
    width: 20%;
}
.c-view {
    text-align: center;
}
.form-control:disabled {
    background: #e1e1e1;
}
.foot-title {
    font-size: 20px;
    color: #112233;
}
#sidebar-menu ul li a {
    font-size: 16px;
}
.dropdown-menu .dropdown-item:hover {
    color: #1e3ed8;
}

/* remove validation icons */
.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: none !important;
    border-color: #ced4da;
    padding-right: 0.75em;
}

.form-control.is-valid,
.was-validated .form-control:valid {
    background-image: none !important;
    border-color: #ced4da;
    padding-right: 0.75em;
}

input::-ms-reveal,
input::-ms-clear {
    display: none;
}

/* 24 AUG 2023*/
#page-topbar {
}

.header-easy {
    background: #1fa310 !important;
}
.footer-page {
    font-size: 22px;
    background: #062575;
    box-shadow: none;
    border-radius: 5px;
    padding: 35px;
    color: #fff;
}
// .pl-20{margin-left: 20px !important;}
.simplebar-bg {
    background-color: #efeaf9;
}
.page-header-user-dropdown:active {
    color: #fff !important;
}
.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
    color: #fff !important;
}

#sidebar-menu ul li a:hover {
    color: #062575 !important;
}

#sidebar-menu ul li a {
    border-bottom: 1px solid #1c2742;
}

body[data-sidebar='dark'] #sidebar-menu ul li a:hover i {
    color: #062575 !important;
}

body[data-sidebar='dark'] #sidebar-menu ul .mm-active a:hover i {
    color: #fff !important;
}
#sidebar-menu ul .mm-active a:hover {
    color: #fff !important;
}
.header-profile-user {
    height: 45px !important;
    width: 45px !important;
}
.header-box {
    margin: 0 25px;
}
table thead {
    background: #293552 !important;
    color: #fff !important;
    font-size: 18px !important;
}
table thead th {
    background: #293552 !important;
    color: #fff !important;
    font-size: 18px !important;
}
.table tbody tr {
    border-bottom: 1px solid;
    border-color: #ced4da;
    font-size: 18px;
    color: #112233 !important;
}
.card-header H4 {
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 600;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li .active span {
    color: #fff !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a span {
    color: #293552 !important;
}

#sidebar-menu ul li .active {
    background: #293552 !important;
    color: #fff !important;
}

.outer-lg {
    background: #01ba13;
    border-radius: 20px;
}
.inner-bg {
    background: #fff;
    padding: 20px !important;
}
.login-btn {
    background: #5b73e8;
    border-color: #495cba;
    font-size: 18px;
    letter-spacing: 1px;
    padding: 10px 70px;
}

// .outer-lg .form-label {
//     font-size: 14px !important;
// }
// .doc-mb-2{margin: 0 0 10px!important;}
.img-box {
    border: 1px solid #112233;
    padding: 20px;
    margin-bottom: 60px;
    border-radius: 10px;
    background: #efeaf9;
}
.forgot-pwd a {
    color: #5b73e8;
    font-size: 16px;
}
.forgot-pwd a:hover {
    color: #20a310;
}
.w-break {
    word-break: break-word !important;
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
    cursor: not-allowed !important;
    pointer-events: all;
}

.down-arrow {
    color: #20a310;
    font-size: 28px;
    cursor: pointer;
}

// New CSS
// .mb-5{margin-bottom: 40px;}
.mt-2 {
    margin-top: 20px !important;
}
.mt-3 {
    margin-top: 30px !important;
}
.auto-fit {
    margin: 0 auto !important;
    max-width: 98% !important;
}
.header-main .navbar-nav > .nav-item > .nav-link {
    font-weight: 600;
    text-transform: none;
    font-size: 1.3rem;
}
.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.8);
}
.mx-auto {
    margin: 0 auto;
}
.link-view a {
    color: #20a310;
}

.header-main .navbar-nav > .nav-item {
    margin: 0 10px;
}
.header-main .navbar-nav > .nav-item > .nav-link {
    font-size: 1.5rem;
}

.hght-80vh {
    min-height: 80vh;
}
.hght-60vh {
    min-height: 60vh;
}

.hght-fullvh {
    min-height: 60vh;
}
.section {
    margin: 8% 0;
}

.table-color {
    background-color: #062575 !important;
    color: #fff !important;
}

.plan-list .card-body {
    // background: #fff;
    // border-radius: 10px;
    // border: 1px solid rgba(0, 0, 0, 0.35);

    border-radius: 10px;
    background: #fff;
    box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.2);

    /* box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.35); */
    // margin: 2.50rem !important;
}

.plan-list h4 {
    font-family: 'MYRIADPRO';
}

.plan-list h5 {
    font-family: 'MYRIADPRO';
}

.plan-list .btn-primary {
    font-family: 'MYRIADPRO';
}

.club-memner label {
    font-family: 'MYRIADPRO';
}

.club-memner .accordion-button:not(.collapsed) {
    background-color: #f2f6fc !important;
    font-family: 'MYRIADPRO';
}
.club-memner .accordion-button {
    background-color: #f2f6fc !important;
    font-family: 'MYRIADPRO';
    color: #000;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
}

.club-memner .accordion-item {
    margin-bottom: 15px;
}

.plan-list .shadow-only-hover {
    padding: 30px;
}
.plan-list .shadow-only-hover:hover {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}
.head-title h2 {
    font-weight: bold;
}
.head-title {
    margin-top: 15px;
}
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show > .nav-link {
    color: #fff;
}

.img-responsive {
    height: auto;
    max-width: 100%;
}
.fixed-width {
    width: 90%;
    background-color: #fff;
}

//01 Nov 23
// .outer-lg .form-label {
//     font-size: 18px !important;
// }
.me-2 {
    margin-right: 0.5rem !important;
}

.plan-list .btn-view {
    padding: 8px 70px;
    font-size: 18px;
}

.text-right {
    text-align: right;
}
.plan-list .form-label {
    color: #000 !important;
    font-family: 'MYRIADPRO' !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    margin-bottom: 0.5rem;
}

.login-data .form-label {
    color: #000 !important;
    font-family: 'MYRIADPRO' !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    margin-bottom: 0.5rem;
}

::-ms-input-placeholder {
    /* Edge 12-18 */
    font-family: 'MYRIADPRO';
    font-size: 18px !important;
}

::placeholder {
    font-family: 'MYRIADPRO';
    font-size: 18px !important;
}

.plan-list ::placeholder {
    font-family: 'MYRIADPRO';
    font-size: 18px !important;
}
.plan-list .form-control {
    font-size: 18px !important;
    font-family: 'MYRIADPRO';
}

.plan-list .form-select {
    font-size: 18px !important;
    font-family: 'MYRIADPRO';
}

.login-data ::placeholder {
    font-family: 'MYRIADPRO';
    font-size: 18px !important;
}
.login-data .form-control {
    font-size: 18px !important;
    font-family: 'MYRIADPRO';
}

.login-data .form-select {
    font-size: 18px !important;
    font-family: 'MYRIADPRO';
}

.plan-title h2 {
    color: #112233;
    font-weight: 700 !important;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    margin-top: 0;
    font-size: 2rem !important;
}

.plan-note p {
    color: #112233;
    font-family: 'MYRIADPRO';
    font-size: 1.4rem !important;
}
.event-box h4 {
    color: #112233;
    font-family: 'MYRIADPRO';
}

.cust-drop button {
    background: none !important;
    padding: 0 !important;
    font-weight: 600 !important;
    text-transform: none !important;
    font-size: 1.5rem !important;
    font-family: 'MYRIADPRO';
    color: rgba(255, 255, 255, 0.8) !important;
    border-color: #20a310 !important;
}

.cust-drop .btn- {
    outline: none Im !important;
    border-color: #20a310 !important;
    box-shadow: none !important;
}

.cust-drop .btn-check:checked + .btn,
.cust-drop :not(.btn-check) + .btn:active,
.cust-drop .btn:first-child:active,
.cust-drop .btn.active,
.cust-drop.btn.show {
    border-color: #20a310 !important;
    outline: none !important;
    box-shadow: none !important;
}

.cust-drop .btn:focus-visible {
    border-color: #20a310 !important;
    outline: none !important;
    box-shadow: none !important;
}

.cust-drop .dropdown-item a {
    color: #000 !important;
    font-size: 18px;
}
.cust-drop .dropdown-item:hover {
    color: #01ba13;
}

.ml-0 {
    margin-left: 0 !important;
}

.fz-22px {
    font-size: 22px !important;
}

.cal-icon {
    position: absolute;
    right: 28px;
    top: 43px;
    font-size: 33px !important;
}

// .cust-drop .dropdown-menu  {
//     padding: 20px;
//    }
// .cust-drop .dropdown-item  {
//  padding: 0;
// }

.regi-box {
    background: #efeaf9;
    height: 650px;
    overflow: scroll;
    border: 1px solid #ccc;
    box-shadow: 5px 16px 28px rgba(0, 0, 0, 0.2);
}
.regi-box p {
    font-size: 20px;
    color: #112233;
}

.regi-box p strong {
    font-size: 20px;
    color: #112233;
}

.regi-box h3 {
    font-size: 28px;
    margin: 35px 0;
    text-align: center;
    color: #112233;
}

.regi-box .h3,
.regi-box h3 {
    color: #123;
    font-size: 28px;
    margin: 35px 0;
    text-align: center;
}

.hv-acc a {
    color: #20a310;
}
.cursor-allowed {
    cursor: not-allowed;
}

.plan-icon {
    font-size: 1.5rem;
    padding: 10px 12px;
    border-radius: 50%;
}

.page-link {
    padding: 0.675rem 1.3em;
    color: #000;
}
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #1fa310;
    border-color: #1fa310;
    border-width: 2px;
}

.top-pagination {
    overflow: auto !important;
}
.mr-box {
    margin: 10px 0 30px;
}
.pagination {
    display: flex;
    list-style: none;
    padding-left: 0;
}

.plan-list .btn-primary {
    border: 1px solid #d9d9d9 !important;
}

.login-before {
    font-family: 'MYRIADPRO' !important;
    font-size: 24px !important;
}

.page-cur .disabled {
    cursor: not-allowed !important;
    pointer-events: all;
}

.page-alpha .pagination {
    overflow: auto !important;
}

.plan-list .font-tab tr th {
    color: #fff !important;
    font-family: 'MYRIADPRO' !important;
    font-size: 20px !important;
    font-weight: 500 !important;
}

.plan-list .font-tab tr td {
    color: #000 !important;
    font-family: 'MYRIADPRO' !important;
    font-size: 20px !important;
    font-weight: 500 !important;
}

.plan-list .text-warning {
    color: #f1b44d !important;
    font-size: 20px;
    font-family: 'MYRIADPRO' !important;
}
.plan-list .cart-note {
    padding: 0 5px;
    font-size: 20px;
    color: #fff;
    font-family: 'MYRIADPRO' !important;
}
.cnt-spce {
    text-align: right !important;
}

.plan-list .cmplte-pay {
    background: #082575;
    margin-bottom: 50px;
    color: #fff;
    border-radius: 5px;
    padding: 7px 0;
}
.main-plan-width {
    width: 60%;
}

.border-box-v {
    background: #fff;
    border: 1px solid #efefef;
    padding: 30px;
    box-shadow: none !important;
    filter: drop-shadow(5px 5px 20px rgba(0, 0, 0, 0.25));
    border-radius: 8px;
}

.mt-1px {
    margin-top: 10px;
}
.mrv-10px {
    margin: 12px 4px;
}
.react-datepicker-popper {
    transform: initial !important;
}

.sanc-acc .accordion-button:not(.collapsed)::after {
    background-image: none;
}

.ip-radio {
    border: 1px solid #000 !important;
}
.ip-radio:hover {
    border: 1px solid #000 !important;
}
//Media
@media only screen and (max-width: 1599px) {
    .main-plan-width {
        width: 90%;
    }
}

@media only screen and (max-width: 1499px) {
    .hght-80vh {
        min-height: auto;
    }
    .hght-60vh {
        min-height: auto;
    }
    .res-width img {
        width: 100%;
        margin-top: 30px;
    }
    .plan-title {
        margin-top: 50px;
    }
    .res-fs {
        font-size: 16px !important;
    }
    .res-mt-5 {
        margin-top: 50px;
    }
    .cnt-spce {
        margin-top: 40px;
    }
    .box-view p {
        font-size: 20px !important;
    }
}
//menu css
@media only screen and (max-width: 1430px) {
    .header-main .navbar-nav > .nav-item > .nav-link {
        font-size: 1.2rem;
    }
}
@media only screen and (max-width: 1430px) {
    .header-main .navbar-nav > .nav-item > .nav-link {
        font-size: 1.1rem;
    }
    .cust-drop button{
        font-size: 1.2rem !important;
    }
}

@media only screen and (max-width: 1199px) {
    .box-view{margin-top: 30px !important;  margin-left: 0px !important;margin-right: 0 !important;} 
}


@media only screen and (max-width: 991px) {
    .plan-hght-80vh {
        min-height: 80vh;
    }
    .main-plan-width {
        width: 90%;
    }
    .res-dis-none {
        display: none;
    }
    .login-before {
        text-align: center;
    }
    .res-center {
        text-align: center;
    }

    // .prof-icn{position: absolute;left: -125px;}

    .res-mt-5 {
        margin-top: 0px;
    }

    .last-btn {
        margin-top: 50px;
    }
    .res-top-space {
        margin-top: 30px;
    }
    .cnt-spce {
        text-align: left !important;
    }

    .user-icon::before {
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        margin-right: 10px;
        font-size: 1.6rem !important;
    }
    .u-font::before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: '\f007';
        font-weight: 900;
        font-size: 1.6rem !important;
        color: #fff;
    }

    .hght-fullvh {
        min-height: auto;
    }
}

@media only screen and (max-width: 767px) {
    .plan-title {
        margin-bottom: 20px !important;
    }
    .navbar-brand {
        margin: 0 auto;
    }
    .fixed-width {
        width: 100%;
    }
    .res-text-center {
        text-align: center;
    }
    .plan-title h2,
    .plan-title .h2 {
        font-size: 1.6rem !important;
    }
    .last-btn {
        margin-top: 0px;
    }
    .cnt-spce {
        margin-top: 0px;
    }
    .header-main .navbar-nav > .nav-item > .nav-link {
        font-size: 1.2rem;
    }
    .cust-drop button {
        font-size: 1.2rem !important;
    }
    .user-icon::before {
        font-size: 1.2rem !important;
    }
    .u-font::before {
        font-size: 1.2rem !important;
    }
    .w-break {
        word-break: break-all !important;
    }
}
@media only screen and (max-width: 480px) {
    .navbar-brand img {
        height: 50px;
    }
    .footer-page {
        font-size: 16px;
    }
    .res-text-center {
        text-align: center;
    }
    .cust-tab .res-w-10 {
        width: 10% !important;
    }
    .last-btn {
        margin-bottom: 20px !important;
    }

    .plan-title {
        justify-content: center !important;
    }
    .login-before {
        font-size: 20px !important;
    }
    .plan-list .shadow-only-hover {
        padding: 10px;
    }
    .res-p-0 {
        padding: 0;
    }
}
@media only screen and (max-width: 360px) {
    .navbar-brand img {
        height: 40px;
    }
}

:disabled,
input:disabled,
.form-check-input:disabled {
    cursor: not-allowed !important;
    pointer-events: all;
}

.hr {
    color: inherit;
    background-color: currentColor;
    border: 1px solid;
    height: 2px;
    opacity: 0.25;
}

.sub-btn{border: none;margin-top:10px;}
.sub-user-div td{background-color: #efeaf9;}
.sub-icn{padding-left:8px;}
.sub-main-div td{background-color: #eeeeee;text-align: left;font-weight: 600;}
.sub-main-div:hover{background-color: #eeeeee;text-align: left;}

.term-modal {
    max-width: 95% !important;
    margin: 1.75rem auto;
}
.term-modal .modal-header {
    align-items: center;
    border-bottom: 1px solid #e2e8f0;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    padding: 1rem;
}
.term-modal .modal-body {
    overflow: scroll;
    height: 700px;
}
.term-modal .close-btn {
    background-color: #fb0001;
    border: none;
    color: #ffffff;
    padding: 13px;
    width: 30px;
    border: 1px solid #fb0001;
    cursor: pointer;
    top: 25px;
    right: 25px;
    opacity: 1;
}


.top-title span {
    text-transform: uppercase;
    font-size: 1.75rem;
    font-weight: bold;

}
.terms a{color: #01ba13;}
.btn-view-ok {
    background-color: #0029a3;
    border-color: #0029a3;
    color: #fff;
}

.box-view {
    border: 1px solid #112233;
    padding: 30px;
    border-radius: 10px;
    margin: 0 20px;
}

.box-view p {
    font-size: 22px;
}